.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #003f8c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fa-brands {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
}

.fa-brands:hover {
  opacity: 0.7;
}







/* Twitter */
.fa-x-twitter {
  background: #55acee;
  color: white;
}

/* Linkedin */
.fa-linkedin {
  background: #55acee;
  color: white;
}

/* Reddit */
.fa-reddit {
  background: #55acee;
  color: white;
}

/* GitHub */
.fa-github {
  background: #55acee; /* GitHub 아이콘에 대한 배경색 */
  color: white;
}

/* Instagram */
.fa-instagram {
  background: #55acee; /* GitHub 아이콘에 대한 배경색 */
  color: white;
}

/* Youtube */
.fa-youtube {
  background: #55acee; /* GitHub 아이콘에 대한 배경색 */
  color: white;
}

.fa-solid {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
}

.fa-solid:hover {
  opacity: 0.7;
}

/* blog */
.fa-house {
  background: #55acee;
  color: white;
}

